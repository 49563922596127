import { Component, OnInit } from "@angular/core";
import {BaseComponent} from '../../../custom-class/base.page';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CoreCommonModule} from '../../../../@core/common.module';
import {CoreTableModule} from '../../../../@core/components';
import {FormGroupPro} from '../../../custom-class/form-group-pro';
import {FormControl, Validators} from '@angular/forms';

@Component({
    selector: 'app-form-contatto',
    templateUrl: './form-contatto.component.html',
    styleUrls: ['./form-contatto.component.scss'],
    standalone: true,
    imports: [
        CoreCommonModule
    ]
})
export class FormContattoComponents extends BaseComponent implements OnInit {
    segnalazioneErroriForm: FormGroupPro;
    loadingForm: boolean = false;
    constructor(
        public activeModal: NgbActiveModal,
    ) {
        super();
    }

    ngOnInit() {
        this.segnalazioneErroriForm = new FormGroupPro({
            nome: new FormControl(null, Validators.required),
            email: new FormControl(null, Validators.required),
            descrizioneErrore: new FormControl(null, Validators.required)
        })
    }
}
