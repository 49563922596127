import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { ToastrModule } from "ngx-toastr"; // For server after login toast
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";

import { CoreModule } from "@core/core.module";
import { CoreCommonModule } from "@core/common.module";
import {
  CoreSidebarModule,
  CoreTableModule,
  CoreThemeCustomizerModule,
} from "@core/components";

import { coreConfig } from "app/app-config";

import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import {
  DialogService,
  ErrorInterceptor,
  JwtInterceptor,
} from "./server/helpers";
import localeIt from "@angular/common/locales/it";
import { DatePipe, registerLocaleData } from "@angular/common";
import { CoreBlockUiComponent } from "@core/components/core-card/core-block-ui/core-block-ui.component";
import { CoreCardModule } from "@core/components/core-card/core-card.module";

registerLocaleData(localeIt);

const appRoutes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./main/pages/pages.module").then((m) => m.PagesModule),
  },
  {
    path: "pages",
    redirectTo: "/home",
    pathMatch: "full",
  },
  {
    path: "**",
    redirectTo: "/miscellaneous/error", // Error 404 - Page not found
  },
];

@NgModule({
  declarations: [AppComponent],
  providers: [
    DialogService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'it-IT' }
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: "enabled", // Add options right here
      // relativeLinkResolution: 'legacy',
      onSameUrlNavigation: "reload",
    }),
    TranslateModule.forRoot(),

    // NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),
    SweetAlert2Module.forChild(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,
    CoreTableModule,

    // App modules
    LayoutModule,
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
