import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {environment} from 'environments/environment';
import {CommonResponse, User} from 'app/server/models';
import {Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class UserService {
  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient) {
  }

  /**
   * Get all users
   */
  getAll(): Observable<CommonResponse<Array<User>>> {
    return this._http.get<CommonResponse<Array<User>>>(`${environment.apiUrl}/users`);
  }

  /**
   * Get user by id
   */
  getById(id: number): Observable<CommonResponse<User>> {
    return this._http.get<CommonResponse<User>>(`${environment.apiUrl}/users/${id}`);
  }

  insertUser(user: User): Observable<CommonResponse<User>> {
    return this._http.post<CommonResponse<User>>(`${environment.apiUrl}/users/create`, user);
  }

  updateUser(user: User): Observable<CommonResponse<User>> {
    return this._http.post<CommonResponse<User>>(`${environment.apiUrl}/users/${user.id}/update`, user);
  }

  deleteUser(id: number): Observable<CommonResponse<boolean>> {
    return this._http.get<CommonResponse<boolean>>(`${environment.apiUrl}/users/${id}/delete`);
  }

  changeState(id: number, state: boolean): Observable<CommonResponse<User>> {
    const stateNew = (state) ? 1 : 0;
    return this._http.get<CommonResponse<User>>(`${environment.apiUrl}/users/${id}/state/${stateNew}`);
  }

  currentUser(): Observable<CommonResponse<User>> {
    return this._http.get<CommonResponse<User>>(`${environment.apiUrl}/auth/user-profile`);
  }

  updateProfilo(user: User): Observable<CommonResponse<User>> {
    return this._http.post<CommonResponse<User>>(`${environment.apiUrl}/users/profile/update`, user);
  }

}
