<li ngbDropdown class="nav-item dropdown-notification mr-25">
  <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown"
    ><i class="ficon" data-feather="bell"></i
    ><span class="badge badge-pill badge-danger badge-up">6</span></a
  >
  <ul
    ngbDropdownMenu
    aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right"
  >
    <!-- Notifications header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 me-auto">Notifications</h4>
        <div class="badge badge-pill badge-light-primary">
          6 New
        </div>
      </div>
    </li>
    <!--/ Notifications header -->

    <!-- Notifications content -->
    <li class="scrollable-container media-list p-2">

      <a href="javascript:void(0)" class="d-flex ng-star-inserted mb-2">
        <div class="media d-flex align-items-start">
          <div class="media-body"><div class="media-heading"><span class="font-weight-bolder">
Congratulation Sam 🎉</span> winner!</div><small class="notification-text">
          Won the monthly best seller badge.
        </small>
        </div>
        </div>
      </a>

      <a href="javascript:void(0)" class="d-flex ng-star-inserted mb-2"><div class="media d-flex align-items-start">
        <div class="media-body"><div class="media-heading"><span class="font-weight-bolder">New message</span>  received</div><small class="notification-text">You have 10 unread messages</small></div></div></a>
      <a href="javascript:void(0)" class="d-flex ng-star-inserted mb-2"><div class="media d-flex align-items-start">
        <div class="media-body"><div class="media-heading"><span class="font-weight-bolder">Revised Order 👋</span>  checkout</div><small class="notification-text">MD Inc. order updated</small></div></div></a>


    </li>
    <!--/ Notifications content -->

    <!-- Notifications footer -->
    <li class="dropdown-menu-footer">
      <a class="btn btn-primary d-block w-100 waves-effect waves-light" href="javascript:void(0)">Read all notifications</a>
    </li>
    <!--/ Notifications footer -->
  </ul>
</li>
