<ng-container *ngIf="!item.hidden">
  <!-- item with router url -->
    @if	(item.id !== 'invia-segnalazioni') {
        <!-- item with router url -->
        <a
                class="d-flex align-items-center"
                [ngClass]="item.classes"
                *ngIf="item.url && !item.externalUrl"
                [routerLink]="[item.url]"
                [target]="item.openInNewTab ? '_blank' : '_self'"
        >
            <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>

        <!-- item with external url -->
        <a
                class="d-flex align-items-center"
                [ngClass]="item.classes"
                *ngIf="item.url && item.externalUrl"
                [href]="item.url"
                [target]="item.openInNewTab ? '_blank' : '_self'"
        >
            <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>
    } @else {
        <a
                class="d-flex align-items-center"
                [ngClass]="item.classes"
        >
            <ng-container *ngTemplateOutlet="itemContent"></ng-container>
        </a>
    }

  <ng-template #itemContent>
    <fa-icon [icon]="item.icon" *ngIf="item.icon"></fa-icon>
    <span class="menu-title text-truncate" [translate]="item.translate">{{ item.title }}</span>
    <span
      class="badge ms-auto me-1"
      *ngIf="item.badge"
      [translate]="item.badge.translate"
      [ngClass]="item.badge.classes"
    >
      {{ item.badge.title }}
    </span>
  </ng-template>
</ng-container>
