import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {AuthenticationService} from 'app/server/services';
import jwt_decode, {JwtPayload} from "jwt-decode";

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router,
              private _authenticationService: AuthenticationService) {
  }

  // canActivate
  async canActivate(route: ActivatedRouteSnapshot,
                    state: RouterStateSnapshot) { //: Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    
    const currentUser = this._authenticationService.currentUserValue;

    if (currentUser) {
      // let currentTimestamp: number = +(new Date().getTime() / 1000).toFixed();
      // // console.log(currentTimestamp - jwt_decode<JwtPayload>(currentUser).nbf);
      // if (currentTimestamp - jwt_decode<JwtPayload>(currentUser).nbf >= 300) { //il token è stato generato più di 5 min fa
      //   await this._authenticationService.refreshToken().toPromise().catch((error) => {
      //     this._authenticationService.logout();
      //     this._router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
      //   });
      return true;
    }
    

    // not logged in so redirect to login page with the return url
    this._router.navigate(['/authentication/login'], {queryParams: {returnUrl: state.url}});
    return false;
  }
}
