import {
  Component,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { AuthenticationService } from "app/server/services";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { CoreConfigService } from "@core/services/config.service";
import { CoreMediaService } from "@core/services/media.service";

import { User } from "app/server/models";
import { Router } from "@angular/router";
import {WebClientiService} from '../../../server/services/webClienti.service';
import {Cliente} from '../../../server/models/cliente';
import {FormContattoComponents} from '../../../main/components/form-contatto/form-contatto.components';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit, OnDestroy {
  public horizontalMenu: boolean;
  public hiddenMenu: boolean;
  user: any;
  clienti: Array<Cliente>;

  public coreConfig: any;
  public currentSkin: string;
  public prevSkin: string;

  public currentUser: User;

  public languageOptions: any;
  public navigation: any;
  public selectedLanguage: any;

  @HostBinding("class.fixed-top")
  public isFixed = false;

  @HostBinding("class.navbar-static-style-on-scroll")
  public windowScrolled = false;

  // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop > 100) &&
      this.coreConfig.layout.navbar.type == "navbar-static-top" &&
      this.coreConfig.layout.type == "horizontal"
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreMediaService} _coreMediaService
   * @param {MediaObserver} _mediaObserver
   */
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
    private _coreConfigService: CoreConfigService,
    private _coreMediaService: CoreMediaService,
    private _coreSidebarService: CoreSidebarService,
    private _webClientiService: WebClientiService,
    private _modalService: NgbModal,
  ) {
    this._authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x),
    );

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  // openModalSupport() {
  //   const modalRef = this._modalService.open(FormContattoComponents, {
  //     ariaLabelledBy: 'modal-basic-title',
  //     centered: true,
  //     size: 'lg',
  //   });
  // }

  /**
   * Toggle Dark Skin
   */
  toggleDarkSkin() {
    // Get the current skin
    this._coreConfigService
      .getConfig()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.currentSkin = config.layout.skin;
      });

    // Toggle Dark skin with prevSkin skin
    this.prevSkin = localStorage.getItem("prevSkin");

    if (this.currentSkin === "dark") {
      this._coreConfigService.setConfig(
        { layout: { skin: this.prevSkin ? this.prevSkin : "default" } },
        { emitEvent: true },
      );
    } else {
      localStorage.setItem("prevSkin", this.currentSkin);
      this._coreConfigService.setConfig(
        { layout: { skin: "dark" } },
        { emitEvent: true },
      );
    }
  }

  /**
   * Logout method
   */
  logout() {
    this._authenticationService.logout(
      JSON.parse(localStorage.getItem("user")),
    );
    this._router.navigate(["/authentication/login"]);
  }

  redirectToHome() {
    this._router.navigate(["./home"]);
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.user = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("user")),
    );

    // Subscribe to the config changes
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
        this.horizontalMenu = config.layout.type === "horizontal";
        this.hiddenMenu = config.layout.menu.hidden === true;
        this.currentSkin = config.layout.skin;

        // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
        if (this.coreConfig.layout.type === "vertical") {
          this._coreMediaService.onMediaUpdate
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
              const isCollapsible = window.matchMedia("(min-width: 1200px)");

              if (isCollapsible.matches) {
                this.isFixed = true;
              } else {
                this.isFixed = false;
              }
            });
        }
      });

    // Horizontal Layout Only: Add class fixed-top to navbar below large screen
    if (this.coreConfig.layout.type == "horizontal") {
      this._coreMediaService.onMediaUpdate
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
          this._coreMediaService.onMediaUpdate
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
              const isCollapsible = window.matchMedia("(min-width: 1200px)");

              if (isCollapsible.matches) {
                this.isFixed = false;
              } else {
                this.isFixed = true;
              }
            });
        });
    }

    if (this._authenticationService.currentUserValue && !this.clienti) {
      //cod_cliente: null, flag_interattiva: null, codice: null, nominativo: null, partita_iva: null, codice_fiscale: null
      this._webClientiService.searchClienti({}).subscribe(res => {
        this.clienti = res.filter(cliente => cliente.gruppo !== 'NO WEB');
      });
    }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
