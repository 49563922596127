import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	template: ` <button [class]="class" (click)="btnClickedHandler($event)">{{ diplayText }}</button> `,
})
export class BtnCellClickRendererComponent implements ICellRendererAngularComp {
	private params: any;
	diplayText: string;
	class: string;

	constructor(
		private _router: Router,
		private _route: ActivatedRoute
	) {}

	agInit(params: any): void {
		this.params = params;
		if (this.params.label) {
			this.diplayText = this.params.label;
		} else {
			this.diplayText = this.params.value;
		}

		if (this.params.class) {
			this.class = this.params.class;
		} else {
			this.class = 'btn btn-primary stile-btn';
		}
	}

	btnClickedHandler($event) {
		this.params.onClick(this.params.data);
	}

	// gets called whenever the cell refreshes
	refresh(params: ICellRendererParams) {
		return true;
		// set value into cell again
	}
}
