<ngb-toast [autohide]="false" *ngIf="_documentiService.isDownloadingSubject.value" class="fixed-bottom ms-auto">
    <div class="row align-items-center">
        <div class="col-8">
            <div class='mb-25'>Download file&hellip; {{ _documentiService.completedDownloads }} / {{_documentiService.selectedDocumentIds.length}}</div>
            <ngb-progressbar [type]="progressBarType" [value]='downloadProgress'></ngb-progressbar>
        </div>
        <div class="col-4">
            <button class="btn-outline-danger p-1 rounded-pill" (click)="cancelDownload()">Annulla</button>
        </div>
    </div>
    <div *ngIf="downloadError" class="text-danger">
        {{ downloadError }}
    </div>
</ngb-toast>
