import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { Polizza } from "../models/polizza";
import { Cliente } from "../models/cliente";

@Injectable({
    providedIn: 'root'
})
export class WebClientiService {
    constructor (private _http: HttpClient) {}

    getDettaglioCliente(cod_cliente: number): Observable<any> {
        return this._http.get<any>(`${environment.apiUrl}/webClienti/view/${cod_cliente}`)
    }

    searchClienti(filter: {flag_interattiva?: number, codice?: number, nominativo?: string, partita_iva?: string, codice_fiscale?: string, cod_cliente?: string}): Observable<Array<Cliente>> {
        return this._http.post<Array<Cliente>>(`${environment.apiUrl}/webClienti/search`, filter)
    }
}
