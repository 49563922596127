import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "app/custom-class/base.page";
import {DocumentiService} from '../../../server/services/documenti.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-mass-download-progress',
    templateUrl: './mass-download-progress.component.html',
    styleUrls: ['./mass-download-progress.component.scss']
})
export class MassDownloadProgressComponent extends BaseComponent implements OnInit {
    downloadProgress = 0;
    downloadError: string;
    progressBarType: string = 'primary'
    constructor(
        public _documentiService: DocumentiService,
        private _toastService: ToastrService,
    ) {
        super();
    }

    cancelDownload(): void {
        this._documentiService.cancelDownload();
    }

    ngOnInit() {
        this._documentiService.downloadProgress$.subscribe(progress => {
            this.downloadProgress = progress;
        });

        this._documentiService.downloadError$.subscribe(error => {
            this.downloadError = error;

            if (error) {
                this.progressBarType = 'danger';
                setTimeout(() => {
                    this.cancelDownload();
                }, 3000);
            } else {
                this.progressBarType = 'primary';
            }
        });
    }
}
