import { Injectable } from "@angular/core";
import {HttpBackend, HttpClient, HttpHeaders} from '@angular/common/http';
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { JwtPayload } from "../models";
import { jwtDecode } from "jwt-decode";
import { environment } from "environments/environment";
import {
  CommonResponse,
  LoginResponse,
  LogoutResponse,
  User,
  Userdata,
} from "app/server/models";
import { DialogService } from "../helpers";
import { Router } from "@angular/router";
import { CoreMenu } from "../../../@core/types";
import { Validators } from "@angular/forms";
import {CookieService} from 'ngx-cookie-service';

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  // public
  public currentUser: any;
  public refreshingToken: boolean = false;
  _http: HttpClient;

  // private
  private currentUserSubject: BehaviorSubject<string>;
  public currentUserObj: BehaviorSubject<any>;

  /**
   *
   * @param {HttpBackend} handler
   * @param {DialogService} _dialogService
   * @param {Router} _router
   */
  constructor(
    handler: HttpBackend,
    private _dialogService: DialogService,
    private _router: Router,
    private _cookieService: CookieService,
  ) {
    this._http = new HttpClient(handler);
    this.currentUserSubject = new BehaviorSubject<string>(
      JSON.parse(sessionStorage.getItem("currentUser")),
    );
    this.currentUserObj = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("user")),
    );
    this.currentUser = this.currentUserObj.asObservable();
    if (this.currentUserValue) {
      this.getCurrentUser();
    }
  }

  // getter: currentUserValue
  public get currentUserValue(): string {
    return this.currentUserSubject.value;
  }

  /**
   * User login
   *
   * @param request
   * @returns user
   */

  login(request: {
    grant_type: string;
    client_id: string;
    username: string;
    password: string;
    cod_societa: number;
    id_cli: string;
    scope: string;
  }): Observable<any> {

    // this._cookieService.set('noteup_vers', "i7-1_0_0_0", {expires: 20, path: '/', domain: '.hit.it'})

    return this._http.post<LoginResponse>(
        `${environment.apiUrl}/webToken/login`,
        request,
        {
          withCredentials: true,
          // headers: new HttpHeaders({
          //   'Content-Type': 'application/json',
          //   Cookie: 'noteup_id_cli=U043'
          // }),
        }
    ).pipe(
        map((data: LoginResponse) => {
          // login successful if there's a jwt token in the response

          if (data) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            sessionStorage.setItem("currentUser", JSON.stringify(data.token));
            localStorage.setItem("user", JSON.stringify(data));
            // Display welcome toast!
            setTimeout(() => {
              this._dialogService.showToast(
                "success",
                "Benvenuto!",
                "Login effettuato correttamente",
              );
            }, 1000);

            // notify
            this.currentUserSubject.next(data.token);
            this.currentUserObj.next(data)
            // this.getCurrentUser();
          }
          return data;
        }),
      );
  }

  U043() {
    return this._http.get(`${environment.apiUrl}/data/societa/U043`);
  }

  resetPassword(data: {
    grant_type?: string,
    client_id?: string,
    client_secret?: string,
    username?: string,
    password?: string,
    newpassword?: string,
    confirmpassword?: string,
    refresh_token?: string,
    cod_societa?: number,
    sblocco_token?: string,
    time_token?: string,
    verification_code?: string,
    id_cli?: string,
    authenticator_key?: string,
    key_otp?: string,
    id_auth?: string,
    user_impersonification?: number
  }): Observable<any> {
    return this._http.post<any>(`${environment.apiUrl}/WebToken/reset_pwd`, data);
  }

  /**
   * User logout
   *
   */
  logout(user: User) {
    let data = {
      id_cli: user.id_cli,
      cod_societa: user.cod_societa,
      refresh_token: user.refresh_token,
      client_id: user.cod_genias,
    };

    sessionStorage.removeItem('filtriRicercaClienti');
    sessionStorage.removeItem('filtriRicercaPolizze');
    sessionStorage.removeItem('filtriRicercaTitoli');

    return this._http
      .post<LogoutResponse>(`${environment.apiUrl}/WebToken/logout`, data, {
        headers: {
          Authorization: `Bearer ${this.currentUserValue}`,
        },
      })
      .subscribe((res) => {
        if (res.logout === "OK") {
          //remove user from local storage to log user out
          sessionStorage.removeItem("currentUser");
          localStorage.removeItem("user");
          // notify
          this.currentUserSubject.next(null);
          this._router.navigate(["/authentication/login"]);
        }
      });
  }

  refreshToken() {
    this.refreshingToken = true;
    return this._http
      .post<LoginResponse>(
        `${environment.apiUrl}/auth/refresh`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.currentUserValue}`,
          },
        },
      )
      .pipe(
        map((data: LoginResponse) => {
          // login successful if there's a jwt token in the response
          if (data) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            sessionStorage.setItem("currentUser", JSON.stringify(data.token));

            // notify
            this.currentUserSubject.next(data.token);
            this.getCurrentUser();
          }

          this.refreshingToken = false;
          return undefined;
        }),
      );
  }

  menuFilter(menu: CoreMenu[], role_id: number) {
    // if (this.currentUserObj.value.fiscal_code) {
    //   menu.find((res) => res.id === 'bustepaga_cud').children[0].url = `${environment.apiUrl}/request/payroll/${this.currentUserValue}`;
    // } else {
    //   menu = menu.filter((res) => res.id !== 'bustepaga_cud');
    // }

    return menu.filter((item) => this.itemMenuFilter(item, role_id));
  }

  filterItems(partialMenu: Array<CoreMenu>, role_id: number): Array<CoreMenu> {
    return partialMenu.filter((item) => {
      if (item.children?.length > 0 && !item.roles) {
        item.children = this.filterItems(item.children, role_id);
        return item.children?.length > 0;
      } else {

        if (!item?.roles) {
          return true
        } else {
          if (item.roles.includes(role_id)) {
            return true
          } else {
            return false
          }
        }
        return false
      }
    });
  }

  getLoggedUser(): Observable<User> {
    return this._http
      .get<CommonResponse<User>>(`${environment.apiUrl}/auth/user-profile`, {
        headers: {
          Authorization: `Bearer ${this.currentUserValue}`,
        },
      })
      .pipe(map((res) => res.data));
  }

  getUserData(): Userdata {
    const token = this.currentUserValue;
    return jwtDecode(token);
  }

  private getCurrentUser(): void {
    this.getLoggedUser().subscribe(
      (res) => {
        this.currentUserObj.next(res);
      },
      (error) => {
        if (error.status === 401 || error.status === 403) {
          this.logout(this.currentUserObj.value);
          this._router.navigate(["/authentication/login"]);
          // this._router.navigate(['/miscellaneous/not-authorized']);
        }
      },
    );
  }

  private itemMenuFilter(menuItem: CoreMenu, role_id: number) {
    if (menuItem.children) {
      menuItem.children = menuItem.children.filter((item) =>
        this.itemMenuFilter(item, role_id),
      );
    }
    if (menuItem.roles) {
      return menuItem.roles.includes(role_id);
    } else {
      return true;
    }
  }

  isProduttore() {
    return this.currentUserObj.value.rolecode === 2000 ? true : false;
  }

  isUser() {
    return this.currentUserObj.value.rolecode === 1000 ? true : false;
  }

  getUserType() {
    if (this.currentUserObj.value.rolecode === 2000) {
      return "produttore";
    } else if (this.currentUserObj.value.rolecode === 1000) {
      return "user";
    }
  }
}
